export default class Language {
  constructor(rootNode) {
    this.$refs = {
      rootNode,
      selected: document.querySelector('.selected'),
    };

    this.$refs.selected.addEventListener('click', this.toggleMenu.bind(this));
  }

  toggleMenu() {
    if (this.$refs.rootNode.classList.contains('open')) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  openMenu() {
    this.$refs.rootNode.classList.add('open');
  }

  closeMenu() {
    this.$refs.rootNode.classList.remove('open');
  }
}
