import { isEditMode } from '../../../src/js/utils';
import * as ScrollMagic from "@modules/scrollmagic";
import {TweenMax} from "@modules/gsap";

export default class Index {
  constructor(rootNode) {
    if (isEditMode()) {
      return;
    }

    this.$refs = {
      rootNode,
      component: rootNode.querySelector('.textAnimation')
    };

    let textContent = this.$refs.component.textContent.normalize("NFC");
    this.$refs.component.innerHTML = textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
    );

    new ScrollMagic.Scene({
      triggerElement: this.$refs.rootNode,
      triggerHook: 0.5,
      duration: () => {
        return this.$refs.rootNode.offsetHeight
      },
    })
        .on("progress", (e) => {
          const progress = e.progress
          const letters = this.$refs.component.querySelectorAll('.letter');

          letters.forEach(function (l, i) {
            if (i < letters.length * progress) {
              l.style.setProperty("--percentage", 1);
            } else {
              l.style.setProperty("--percentage", 0.1);
            }
          });
        })
        .addTo(new ScrollMagic.Controller())
  }
}
