const win = window;

export default class Navigation {
    constructor(rootNode, props) {
        this.root = rootNode;

        window.addEventListener('scroll', this.scrollHandler.bind(this), {passive: true});
    }

    scrollHandler() {
        if (win.scrollY > 80) {
            this.root.classList.add('sticky');
        } else if(win.scrollY < 30) {
            this.root.classList.remove('sticky');
        }
    }
}
