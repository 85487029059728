export default class ContactForm {
  constructor(rootNode) {
    this.$refs = {
      rootNode,
      form: document.querySelector('form'),
      inputs: document.querySelectorAll('input, textarea, select'),
      checkOnChange: false
    };

    this.$refs.rootNode.addEventListener('change', this.validate.bind(this))
    this.$refs.rootNode.addEventListener('submit', this.submit.bind(this))
  }

  submit(e) {
    e.preventDefault();

    this.$refs.checkOnChange = true

    if(this.validate()) this.$refs.form.submit()
  }

  validate() {
    if(!this.$refs.checkOnChange) return

    let errors = 0

    this.$refs.inputs.forEach((input) => {
      const row = input.closest('.form-row')

      if(!row) return

      if(input.validity.valid) {
        row.classList.remove('has-error')
      } else {
        row.classList.add('has-error')
        row.querySelector('.error-message').innerHTML = input.validationMessage
        errors++
      }
    })

    return (errors <= 0)
  }
};
