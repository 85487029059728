// Components
export { default as AbTextAnimation } from '../../views/components/TextAnimation/TextAnimation';
export { default as ContactForm } from '../../views/components/ContactForm/ContactForm';
export { default as Navigation } from '../../views/components/Navigation/Navigation';
export { default as ImageText } from '../../views/components/ImageText/ImageText';
export { default as ProductTeaser } from '../../views/components/ProductTeaser/ProductTeaser';
export { default as EffectExperienceTeaser } from '../../views/components/EffectExperienceTeaser/EffectExperienceTeaser';
export { default as CookieConsent } from '../../views/components/CookieConsent/CookieConsent';

// Detail pages
export { default as EffectDetail } from '../../views/effect/index.js';

// Scrollmagic
import * as ScrollMagic from "@modules/scrollmagic";
import {TweenMax} from "@modules/gsap";
import {ScrollMagicPluginGsap} from "@modules/scrollmagic-plugins";

ScrollMagicPluginGsap(ScrollMagic, TweenMax);

// Partials
export { default as Language } from './partials/language';
